html,
body {
    height: 100%;
}

main {
    margin: 5rem 0;
}

.my-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.vaccination-complete {
    text-align: center;
    max-width: 500px;
    margin: 2rem auto;
}

.cta-container {
    width: 100%;
    box-shadow: 1px 1px 4px gray;
    background: #ffffff;
    border-radius: 4px;
    overflow: hidden;
}

.login-cta {
    background: #022748;
    text-align: center;
    width: 100%;
    padding: 4rem 0 4rem;
}

.login-cta .ui.header {
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0;
}

.guest-cta {
    width: 100%;
    text-align: center;
    letter-spacing: 0;
    margin-top: 4rem;
    padding: 0 4rem 4rem;
}

.guest-cta-reg {
    font-size: 16px;
    width: 100%;
    text-align: center;
    letter-spacing: 0;
    padding: 1rem 4rem 4rem;
}

.guest-cta .ui.header {
    font-size: 16px;
    letter-spacing: 0;
    margin-bottom: 2.5rem;
}

.message p a {
    color: #0056ac !important;
}

.ui.checkbox input.hidden+label {
    background-color: white;
}

.langButton {
    font-weight: 800 !important;
}